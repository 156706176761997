import React, { useState, useEffect } from 'react';
import { 
  Typography, 
  TextField, 
  Grid, 
  Button, 
  Switch,
  FormControlLabel,
  Box,
  Divider,
  Alert,
  Snackbar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backend from '../external/backend';

// Common styles for text fields
const textFieldStyle = {
  input: { color: 'white' },
  '& .MuiInputLabel-root': { color: 'white' },
  '& .MuiOutlinedInput-root': { 
    '& fieldset': { borderColor: 'white' },
    '&:hover fieldset': { borderColor: 'white' },
    '&.Mui-focused fieldset': { borderColor: 'white' },
    color: 'white'
  },
  '& .MuiFormHelperText-root': { color: 'white' }
};

const accordionStyle = {
  backgroundColor: 'rgba(255, 255, 255, 0.05)',
  mb: 2,
  '& .MuiAccordionSummary-expandIconWrapper': {
    color: 'white'
  }
};

function AccountSettings({ blockchain, accountId, accountData, onAccountUpdated }) {
  const [settings, setSettings] = useState({
    name: accountData.name ?? '',
    requireTransactionCustomers: accountData.settings.requireTransactionCustomers ?? true,
    ...(blockchain === 'ethereum' && {
      supportedTokens: accountData.settings.supportedTokens ?? [],
    }),
    ...(blockchain === 'bitcoin' && {
      batchingDetails: {
        enabled: accountData.settings.batchingDetails?.enabled ?? false,
        frequency: {
          recurrenceRule: accountData.settings.batchingDetails?.frequency?.recurrenceRule ?? 'every',
          unit: accountData.settings.batchingDetails?.frequency?.unit ?? 'minute',
          interval: accountData.settings.batchingDetails?.frequency?.interval ?? 10
        }
      }
    }),
    eventMonitoring: {
      enabled: accountData.settings.eventMonitoring?.enabled ?? false,
      confirmationsToMonitor: accountData.settings.eventMonitoring?.confirmationsToMonitor ?? [],
      eventWebhookUrl: accountData.settings.eventMonitoring?.eventWebhookUrl ?? '',
      monitoringLifetime: accountData.settings.eventMonitoring?.monitoringLifetime ?? 45
    },
    walletLifetime: {
      dedicatedToSingleTransaction: accountData.settings.walletLifetime?.dedicatedToSingleTransaction ?? true,
      maxAgeInHours: accountData.settings.walletLifetime?.maxAgeInHours ?? 4
    },
    transactionCustomers: accountData.transactionCustomers ?? []
  });

  // Add useEffect to update settings when accountData changes
  useEffect(() => {
    setSettings({
      name: accountData.name || '',
      requireTransactionCustomers: accountData.settings.requireTransactionCustomers ?? true,
      ...(blockchain === 'ethereum' && {
        supportedTokens: accountData.settings.supportedTokens ?? [],
      }),
      ...(blockchain === 'bitcoin' && {
        batchingDetails: {
          enabled: accountData.settings.batchingDetails?.enabled ?? false,
          frequency: {
            recurrenceRule: accountData.settings.batchingDetails?.frequency?.recurrenceRule ?? 'every',
            unit: accountData.settings.batchingDetails?.frequency?.unit ?? 'minute',
            interval: accountData.settings.batchingDetails?.frequency?.interval ?? 10
          }
        }
      }),
      eventMonitoring: {
        enabled: accountData.settings.eventMonitoring?.enabled ?? false,
        confirmationsToMonitor: accountData.settings.eventMonitoring?.confirmationsToMonitor ?? [],
        eventWebhookUrl: accountData.settings.eventMonitoring?.eventWebhookUrl ?? '',
        monitoringLifetime: accountData.settings.eventMonitoring?.monitoringLifetime ?? 45
      },
      walletLifetime: {
        dedicatedToSingleTransaction: accountData.settings.walletLifetime?.dedicatedToSingleTransaction ?? true,
        maxAgeInHours: accountData.settings.walletLifetime?.maxAgeInHours ?? 4
      },
      transactionCustomers: accountData.transactionCustomers ?? []
    });
  }, [accountData, blockchain]);

  const [isEditing, setIsEditing] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [availableTokens, setAvailableTokens] = useState([]);

  useEffect(() => {
    if (blockchain === 'ethereum') {
      Backend.getSupportedTokens(blockchain)
        .then(tokens => {
          setAvailableTokens(tokens);
        })
        .catch(error => {
          setErrorMessage('Failed to fetch supported tokens');
          setShowError(true);
        });
    }
  }, [blockchain]);

  const handleChange = (path) => (event) => {
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    setSettings(prev => {
      const newSettings = { ...prev };
      const pathArray = path.split('.');
      let current = newSettings;
      
      for (let i = 0; i < pathArray.length - 1; i++) {
        current = current[pathArray[i]];
      }
      current[pathArray[pathArray.length - 1]] = value;
      
      return newSettings;
    });
  };

  const handleSave = async () => {
    try {
      const body = {
        name: settings.name,
        settings: {
          requireTransactionCustomers: settings.requireTransactionCustomers,
          ...(blockchain === 'ethereum' && {
            supportedTokens: settings.supportedTokens,
          }),
          ...(blockchain === 'bitcoin' && {
            batchingDetails: {
              enabled: settings.batchingDetails.enabled,
            },
          }),
          eventMonitoring: {
            enabled: settings.eventMonitoring.enabled,
          },
          walletLifetime: settings.walletLifetime
        }
      };

      if (blockchain === 'bitcoin' && settings.batchingDetails.enabled) {
        body.settings.batchingDetails = settings.batchingDetails;
      }

      if(settings.eventMonitoring.enabled) {
        body.settings.eventMonitoring = settings.eventMonitoring;
      }

      await Backend.updateHdAccount(blockchain, accountId, body);
      setIsEditing(false);
      onAccountUpdated(body);
      setShowSuccess(true);
    } catch (error) {
      setErrorMessage(error.message || 'Failed to update settings');
      setShowError(true);
    }
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: 3, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box>
          <Typography sx={{ color: 'white' }}>
            Account ID: {accountId}
          </Typography>
          <Typography sx={{ color: 'white' }}>
            Blockchain: {blockchain}
          </Typography>
          <Typography sx={{ color: 'white' }}>
            Number of Wallets: {accountData.numOfWallets}
          </Typography>
        </Box>
        <Button 
          variant="outlined" 
          sx={{ color: 'white', borderColor: 'white' }}
          onClick={() => isEditing ? handleSave() : setIsEditing(true)}
        >
          {isEditing ? 'Save Changes' : 'Edit Settings'}
        </Button>
      </Box>

      <Divider sx={{ borderColor: 'white', mb: 3 }} />

      {/* Basic Settings */}
      <Grid container spacing={3} sx={{ mb: 3 }}>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Account Name"
            value={settings.name}
            onChange={handleChange('name')}
            disabled={!isEditing}
            sx={textFieldStyle}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Switch
                checked={settings.requireTransactionCustomers}
                onChange={handleChange('requireTransactionCustomers')}
                disabled={!isEditing}
                sx={{
                  '& .MuiSwitch-switchBase': {
                    color: 'white',
                    '&.Mui-checked': {
                      color: 'white',
                    },
                  },
                  '& .MuiSwitch-track': {
                    backgroundColor: 'rgba(255, 255, 255, 0.3)',
                  },
                }}
              />
            }
            label="Require Transaction Customers"
            sx={{ color: 'white' }}
          />
        </Grid>
      </Grid>

      {/* Blockchain Specific Settings */}
      {blockchain === 'ethereum' && (
        <Accordion sx={accordionStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography sx={{ color: 'white' }}>Supported Tokens</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Autocomplete
              multiple
              options={availableTokens}
              value={settings.supportedTokens}
              onChange={(event, newValue) => {
                setSettings(prev => ({
                  ...prev,
                  supportedTokens: newValue
                }));
              }}
              disabled={!isEditing}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select tokens"
                  sx={textFieldStyle}
                />
              )}
              sx={{
                '& .MuiAutocomplete-tag': {
                  color: 'white',
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
                '& .MuiAutocomplete-clearIndicator': {
                  color: 'white',
                },
                '& .MuiAutocomplete-popupIndicator': {
                  color: 'white',
                },
              }}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {blockchain === 'bitcoin' && (
        <Accordion sx={accordionStyle}>
          <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
            <Typography sx={{ color: 'white' }}>Batching Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={settings.batchingDetails.enabled}
                      onChange={handleChange('batchingDetails.enabled')}
                      disabled={!isEditing}
                      sx={{
                        '& .MuiSwitch-switchBase': {
                          color: 'white',
                          '&.Mui-checked': {
                            color: 'white',
                          },
                        },
                        '& .MuiSwitch-track': {
                          backgroundColor: 'rgba(255, 255, 255, 0.3)',
                        },
                      }}
                    />
                  }
                  label="Enable Batching"
                  sx={{ color: 'white' }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Recurrence Rule"
                  value={settings.batchingDetails.frequency.recurrenceRule}
                  onChange={handleChange('batchingDetails.frequency.recurrenceRule')}
                  disabled={!isEditing || !settings.batchingDetails.enabled}
                  sx={textFieldStyle}
                  select
                  SelectProps={{
                    native: true,
                    sx: { color: 'white',
                      '& option': {
                        color: 'black'
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <option value="on">on</option>
                  <option value="every">every</option>
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  label="Unit"
                  value={settings.batchingDetails.frequency.unit}
                  onChange={(e) => {
                    // First update the unit
                    handleChange('batchingDetails.frequency.unit')(e);
                    
                    // Then validate the interval based on the new unit
                    const newUnit = e.target.value;
                    const currentInterval = settings.batchingDetails.frequency.interval;
                    
                    const min = newUnit === 'minute' ? 0 : 
                              newUnit === 'hour' ? 0 :
                              newUnit === 'dayOfWeek' ? 1 :
                              newUnit === 'dayOfMonth' ? 1 :
                              newUnit === 'month' ? 1 : 1;
                    
                    const max = newUnit === 'minute' ? 59 : 
                              newUnit === 'hour' ? 23 :
                              newUnit === 'dayOfWeek' ? 7 :
                              newUnit === 'dayOfMonth' ? 28 :
                              newUnit === 'month' ? 12 : 100;
                    
                    const validValue = Math.min(Math.max(currentInterval, min), max);
                    
                    if (currentInterval !== validValue) {
                      const intervalEvent = {
                        target: {
                          value: validValue,
                          type: 'number'
                        }
                      };
                      handleChange('batchingDetails.frequency.interval')(intervalEvent);
                    }
                  }}
                  disabled={!isEditing || !settings.batchingDetails.enabled}
                  sx={textFieldStyle}
                  select
                  SelectProps={{
                    native: true,
                    sx: { color: 'white',
                      '& option': {
                        color: 'black'
                      }
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                >
                  <option value="minute">minute</option>
                  <option value="hour">hour</option>
                  <option value="dayOfWeek">day of week</option>
                  <option value="dayOfMonth">day of month</option>
                  <option value="month">month</option>
                  <option value="year">year</option>
                </TextField>
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  fullWidth
                  type="number"
                  label="Interval"
                  value={settings.batchingDetails.frequency.interval}
                  onChange={handleChange('batchingDetails.frequency.interval')}
                  onBlur={(e) => {
                    const value = parseInt(e.target.value, 10);
                    const min = settings.batchingDetails.frequency.unit === 'minute' ? 0 : 
                              settings.batchingDetails.frequency.unit === 'hour' ? 0 :
                              settings.batchingDetails.frequency.unit === 'dayOfWeek' ? 1 :
                              settings.batchingDetails.frequency.unit === 'dayOfMonth' ? 1 :
                              settings.batchingDetails.frequency.unit === 'month' ? 1 : 1;
                    
                    const max = settings.batchingDetails.frequency.unit === 'minute' ? 59 : 
                              settings.batchingDetails.frequency.unit === 'hour' ? 23 :
                              settings.batchingDetails.frequency.unit === 'dayOfWeek' ? 7 :
                              settings.batchingDetails.frequency.unit === 'dayOfMonth' ? 28 :
                              settings.batchingDetails.frequency.unit === 'month' ? 12 : 100;
                    
                    const validValue = Math.min(Math.max(value, min), max);
                    
                    if (value !== validValue) {
                      const event = {
                        target: {
                          value: validValue,
                          type: e.target.type
                        }
                      };
                      handleChange('batchingDetails.frequency.interval')(event);
                    }
                  }}
                  disabled={!isEditing || !settings.batchingDetails.enabled}
                  sx={textFieldStyle}
                  InputProps={{
                    inputProps: {
                      min: settings.batchingDetails.frequency.unit === 'minute' ? 0 : 
                           settings.batchingDetails.frequency.unit === 'hour' ? 0 :
                           settings.batchingDetails.frequency.unit === 'dayOfWeek' ? 1 :
                           settings.batchingDetails.frequency.unit === 'dayOfMonth' ? 1 :
                           settings.batchingDetails.frequency.unit === 'month' ? 1 : 1,
                      max: settings.batchingDetails.frequency.unit === 'minute' ? 59 : 
                           settings.batchingDetails.frequency.unit === 'hour' ? 23 :
                           settings.batchingDetails.frequency.unit === 'dayOfWeek' ? 7 :
                           settings.batchingDetails.frequency.unit === 'dayOfMonth' ? 28 :
                           settings.batchingDetails.frequency.unit === 'month' ? 12 : 100
                    }
                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Event Monitoring Settings */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Event Monitoring Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.eventMonitoring.enabled}
                    onChange={handleChange('eventMonitoring.enabled')}
                    disabled={!isEditing}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                  />
                }
                label="Enable Event Monitoring"
                sx={{ color: 'white' }}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={blockchain === 'bitcoin' ? [0,1,2,3,4,5,6,7,8,9,10] : [1,2,3,4,5,6,7,8,9,10]}
                value={settings.eventMonitoring.confirmationsToMonitor}
                onChange={(event, newValue) => {
                  setSettings(prev => ({
                    ...prev,
                    eventMonitoring: {
                      ...prev.eventMonitoring,
                      confirmationsToMonitor: newValue
                    }
                  }));
                }}
                disabled={!isEditing || !settings.eventMonitoring.enabled}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select Confirmations to Monitor"
                    sx={textFieldStyle}
                  />
                )}
                sx={{
                  '& .MuiAutocomplete-tag': {
                    color: 'white',
                    backgroundColor: 'rgba(255, 255, 255, 0.1)',
                  },
                  '& .MuiAutocomplete-clearIndicator': {
                    color: 'white',
                  },
                  '& .MuiAutocomplete-popupIndicator': {
                    color: 'white',
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Event Webhook URL"
                value={settings.eventMonitoring.eventWebhookUrl}
                onChange={handleChange('eventMonitoring.eventWebhookUrl')}
                disabled={!isEditing || !settings.eventMonitoring.enabled}
                error={settings.eventMonitoring.eventWebhookUrl && !/^https?:\/\/.+/.test(settings.eventMonitoring.eventWebhookUrl)}
                helperText={settings.eventMonitoring.eventWebhookUrl && !/^https?:\/\/.+/.test(settings.eventMonitoring.eventWebhookUrl) ? "Please enter a valid URL starting with http:// or https://" : ""}
                sx={textFieldStyle}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Monitoring Lifetime (in minutes)"
                value={settings.eventMonitoring.monitoringLifetime}
                onChange={(e) => {
                  // Just store the input value without validation during typing
                  const event = {
                    target: {
                      value: e.target.value,
                      type: e.target.type
                    }
                  };
                  handleChange('eventMonitoring.monitoringLifetime')(event);
                }}
                onBlur={(e) => {
                  // Validate on blur
                  const value = parseInt(e.target.value);
                  if (!(value >= 15 && value <= 120)) {
                    // Reset to valid value if out of range
                    const event = {
                      target: {
                        value: Math.min(Math.max(value, 15), 120) || 15,
                        type: e.target.type
                      }
                    };
                    handleChange('eventMonitoring.monitoringLifetime')(event);
                  }
                }}
                inputProps={{ min: 15, max: 120 }}
                helperText="Value must be between 15 and 120"
                disabled={!isEditing || !settings.eventMonitoring.enabled}
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Wallet Lifetime Settings */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Wallet Lifetime Settings</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={settings.walletLifetime.dedicatedToSingleTransaction}
                    onChange={handleChange('walletLifetime.dedicatedToSingleTransaction')}
                    disabled={!isEditing}
                    sx={{
                      '& .MuiSwitch-switchBase': {
                        color: 'white',
                        '&.Mui-checked': {
                          color: 'white',
                        },
                      },
                      '& .MuiSwitch-track': {
                        backgroundColor: 'rgba(255, 255, 255, 0.3)',
                      },
                    }}
                  />
                }
                label="Dedicated to Single Transaction"
                sx={{ color: 'white' }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                type="number"
                label="Max Age in Hours"
                value={settings.walletLifetime.maxAgeInHours}
                onChange={(e) => {
                  // Just update the value directly during typing
                  const event = {
                    target: {
                      value: e.target.value,
                      type: e.target.type
                    }
                  };
                  handleChange('walletLifetime.maxAgeInHours')(event);
                }}
                onBlur={(e) => {
                  const value = parseInt(e.target.value, 10);
                  let validValue = value;
                  
                  // Check if value is between 2-24 or one of the allowed values
                  if (value < 2) {
                    validValue = 2;
                  } else if (value > 24 && value !== 48 && value !== 72 && value !== 96) {
                    // If greater than 24, only allow 48, 72, or 96
                    if (value > 96) {
                      validValue = 96;
                    } else if (value > 72) {
                      validValue = 72;
                    } else if (value > 48) {
                      validValue = 48;
                    } else {
                      validValue = 24;
                    }
                  }
                  
                  const event = {
                    target: {
                      value: validValue,
                      type: e.target.type
                    }
                  };
                  handleChange('walletLifetime.maxAgeInHours')(event);
                }}
                inputProps={{ 
                  min: 2, 
                  max: 96,
                  step: 1
                }}
                helperText="Value must be between 2-24, or 48, 72, 96"
                disabled={!isEditing}
                sx={textFieldStyle}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {/* Transaction Customers */}
      <Accordion sx={accordionStyle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}>
          <Typography sx={{ color: 'white' }}>Transaction Customers</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            fullWidth
            multiline
            rows={4}
            value={settings.transactionCustomers.join('\n')}
            onChange={(e) => setSettings(prev => ({
              ...prev,
              transactionCustomers: e.target.value.split('\n').filter(customer => customer.trim())
            }))}
            disabled={true}
            placeholder="Shows the IDs of the Transaction Customers that interacted with this account. Transaction Customers are not editable."
            sx={textFieldStyle}
          />
        </AccordionDetails>
      </Accordion>

      <Snackbar 
        open={showSuccess} 
        autoHideDuration={6000} 
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#4caf50' }}>
          Settings updated successfully
        </Alert>
      </Snackbar>

      <Snackbar 
        open={showError} 
        autoHideDuration={6000} 
        onClose={() => setShowError(false)}
      >
        <Alert severity="error" sx={{ width: '100%', color: 'white', backgroundColor: '#f44336' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AccountSettings; 