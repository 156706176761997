import React, { useState, useEffect } from 'react';
import { 
  Container,
  Box, 
  TextField, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination,
  CircularProgress,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Snackbar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backend from './external/backend';

// Common styles for text fields
const textFieldStyle = {
  input: { 
    color: 'white',
    '&::selection': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    cursor: 'text !important'
  },
  '& .MuiInputLabel-root': { color: 'white' },
  '& .MuiOutlinedInput-root': { 
    '& fieldset': { borderColor: 'white' },
    '&:hover fieldset': { borderColor: 'white' },
    '&.Mui-focused fieldset': { borderColor: 'white' },
    color: 'white',
    cursor: 'text !important'
  },
  '& .MuiFormHelperText-root': { color: 'white' },
  '& .MuiInputBase-root': {
    cursor: 'text !important'
  }
};

// Constants
const PAYMENT_TYPES = ['standard', 'RBF'];
const BLOCKCHAINS = ['bitcoin', 'ethereum'];
const PRIORITY_LEVELS = ['slow', 'standard', 'fast'];
const REPLACED_PAYMENT_TYPES = ['batched', 'single'];

function Payments() {
  const [payments, setPayments] = useState([]);
  const [filteredPayments, setFilteredPayments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showInternal, setShowInternal] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [blockchainsAndAccounts, setBlockchainsAndAccounts] = useState({});
  const [createPaymentFormData, setCreatePaymentFormData] = useState({
    id: '',
    paymentType: 'standard',
    blockchain: '',
    account: '',
    amount: '',
    token: '',
    toWalletAddress: '',
    priority: 'slow',
    feeLimit: '',
    replacedPaymentId: '',
    replacedPaymentType: 'batched'
  });
  const [selectedBlockchain, setSelectedBlockchain] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');

  useEffect(() => {
    fetchBlockchainsAndAccounts();
  }, []);

  useEffect(() => {
    if (selectedBlockchain && selectedAccount) {
      fetchPayments();
    } else {
      setPayments([]);
      setFilteredPayments([]);
    }
  }, [selectedBlockchain, selectedAccount]);

  const fetchPayments = async () => {
    setIsLoading(true);
    try {
      const data = await Backend.getAllPaymentRequests(selectedBlockchain, selectedAccount);
      
      // Transform the nested object structure into a flat array
      const transformedPayments = [];
      
      // Process each payment type (batched, single)
      Object.entries(data).forEach(([batchType, statusGroups]) => {
        // Process each status group (queued, in-progress, broadcasted, exception)
        Object.entries(statusGroups).forEach(([status, payments]) => {
          // Process each payment in the status group
          Object.entries(payments).forEach(([id, payment]) => {
            console.log('payment', payment);
            transformedPayments.push({
              ...payment,
              batchType,
              status,
              // Add any additional fields needed for display
              paymentType: payment.paymentType,
              amount: payment.from?.amountInMinor,
              to: payment.to?.walletAddress,
              token: payment.from?.token,
              account: payment.from?.hdIndex,
              source: payment.from?.paymentSource,
              fromWallet: payment.from?.walletAddress,
              feeLimit: payment.maxFeeLimit ?? payment.newMaxFeeLimit ?? 0,
              transactionId: payment.txid,
              broadcastTime: payment.broadcastTime,
              queuedTime: payment.queuedTime,
              errorTime: payment.errorTime,
              replacedTransactionType: payment.replacedTransactionType,
              requestIdToReplace: payment.requestIdToReplace
            });
          });
        });
      });

      // Sort by queued time in descending order (newest first)
      transformedPayments.sort((a, b) => b.queuedTime - a.queuedTime);
      
      setPayments(transformedPayments);
      setFilteredPayments(transformedPayments);
    } catch (error) {
      console.error("Error fetching payments:", error);
      setErrorMessage('Failed to fetch payments');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchBlockchainsAndAccounts = async () => {
    try {
      const chainsAndAccounts = {};
      const balanceData = await Backend.getBalance();
      
      for (const customerKey of Object.keys(balanceData)) {
        const customer = balanceData[customerKey];
        for (const blockchain of Object.keys(customer)) {
          chainsAndAccounts[blockchain] = [];
          for (const hdIndex of Object.keys(customer[blockchain])) {
            const accountInfo = {
              id: hdIndex,
              data: await Backend.getAccountInfo(blockchain, hdIndex)
            };
            chainsAndAccounts[blockchain].push(accountInfo);
          }
        }
      }
      setBlockchainsAndAccounts(chainsAndAccounts);
    } catch (error) {
      console.error("Error fetching blockchains and accounts:", error);
    }
  };

  useEffect(() => {
    let filtered = payments;
    
    // Apply search filter
    if (searchTerm !== '') {
      filtered = filtered.filter(payment =>
        Object.entries(payment).some(([key, value]) => {
          if(value === null || value === undefined) {
            return false;
          }

          if (key === 'queuedTime' || key === 'broadcastTime' || key === 'errorTime') {
            return new Date(value * 1000).toLocaleString("en-GB").toLowerCase().includes(searchTerm.toLowerCase());
          }
          return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        })
      );
    }

    // Apply internal filter
    if (!showInternal) {
      filtered = filtered.filter(payment => payment.paymentType !== 'internal');
    }

    setFilteredPayments(filtered);
    setPage(0);
  }, [searchTerm, showInternal, payments]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handlePaymentSelect = (payment) => {
    setSelectedPayment(payment);
  };

  const handleCreate = async () => {
    try {
      const { id, blockchain, paymentType, account, amount, token, toWalletAddress, priority, feeLimit, replacedPaymentId, replacedPaymentType } = createPaymentFormData;

      if (blockchain === 'bitcoin') {
        if (paymentType === 'standard') {
          console.log('account', account);
          const accountDetails = blockchainsAndAccounts[blockchain].find(acc => acc.id === account);
          console.log("Account details:", accountDetails);
          if (accountDetails?.data?.settings?.batchingDetails?.enabled) {
            console.log("Creating bitcoin standard batched payment request");
            await Backend.createBatchedPaymentRequest(blockchain, account, amount, toWalletAddress, id);
          } else {
            console.log("Creating bitcoin standard single payment request");
            await Backend.createSinglePaymentRequest(blockchain, account, amount, undefined, toWalletAddress, id, priority, feeLimit);
          }
        } else if (paymentType === 'RBF') {
          console.log("Creating bitcoin RBF payment request");
          await Backend.createRbfPaymentRequest(blockchain, account, id, replacedPaymentId, feeLimit, replacedPaymentType);
        }
      } else if (blockchain === 'ethereum') {
        if (paymentType === 'standard') {
          console.log("Creating ethereum standard payment request");
          await Backend.createSinglePaymentRequest(blockchain, account, amount, token, toWalletAddress, id, priority, feeLimit);
        } else if (paymentType === 'RBF') {
          console.log("Creating ethereum RBF payment request");
          await Backend.createRbfPaymentRequest(blockchain, account, id, replacedPaymentId, feeLimit, "single");
        }
      }

      setCreatePaymentFormData({
        id: '',
        paymentType: 'standard',
        blockchain: '',
        account: '',
        amount: '',
        token: '',
        toWalletAddress: '',
        priority: 'slow',
        feeLimit: '',
        replacedPaymentId: '',
        replacedPaymentType: 'batched'
      });
      setShowSuccess(true);
      if (selectedBlockchain && selectedAccount) {
        fetchPayments();
      }
    } catch (error) {
      setErrorMessage(error.message || 'Failed to create payment request');
      setShowError(true);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Section 1: Blockchain and Account Selection */}
        <Grid item xs={12}>
          <Paper sx={{ 
            p: 2, 
            backgroundColor: 'gray', 
            color: 'white',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>
              Select Blockchain and Account
            </Typography>
            <Box sx={{ display: 'flex', gap: 2, mb: 2, alignItems: 'center' }}>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel sx={{ color: 'white' }}>Blockchain</InputLabel>
                <Select
                  value={selectedBlockchain}
                  onChange={(e) => {
                    setSelectedBlockchain(e.target.value);
                    setSelectedAccount(''); // Reset account selection when blockchain changes
                    setSelectedPayment(null);
                  }}
                  label="Blockchain"
                  sx={{ color: 'white' }}
                >
                  {Object.keys(blockchainsAndAccounts).map((blockchain) => (
                    <MenuItem key={blockchain} value={blockchain}>
                      {blockchain}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ minWidth: 200 }}>
                <InputLabel sx={{ color: 'white' }}>Account</InputLabel>
                <Select
                  value={selectedAccount}
                  onChange={(e) => {
                    setSelectedAccount(e.target.value);
                    setSelectedPayment(null);
                  }}
                  label="Account"
                  disabled={!selectedBlockchain}
                  sx={{ color: 'white' }}
                >
                  {selectedBlockchain && blockchainsAndAccounts[selectedBlockchain].map((account) => (
                    <MenuItem key={account.id} value={account.id}>
                      {account.data.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showInternal}
                    onChange={(e) => {
                      setShowInternal(e.target.checked);
                      if(!e.target.checked) {
                        if(selectedPayment && selectedPayment.paymentType === 'internal') {
                          setSelectedPayment(null);
                        }
                      }
                    }}
                    sx={{ color: 'white' }}
                  />
                }
                label="Show Internal"
                sx={{ color: 'white' }}
              />
            </Box>
          </Paper>
        </Grid>

        {/* Section 2: Payment List (only shown when blockchain and account are selected) */}
        {selectedBlockchain && selectedAccount && (
          <Grid item xs={12}>
            <Accordion 
              sx={{ 
                backgroundColor: 'gray', 
                color: 'white',
                '& .MuiAccordionSummary-root': {
                  backgroundColor: 'gray',
                  color: 'white'
                }
              }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="payments-list-content"
                id="payments-list-header"
              >
                <Typography variant="h6">Payments List</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Search payments..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    InputProps={{
                      style: { 
                        color: 'white',
                        caretColor: 'white'
                      }
                    }}
                    sx={textFieldStyle}
                  />
                </Box>
                <TableContainer component={Paper} sx={{ backgroundColor: 'gray' }}>
                  {isLoading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 3 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <Table sx={{ color: 'white' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>ID</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Queued Time</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Amount</TableCell>
                          <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>To</TableCell>
                          {selectedBlockchain === 'ethereum' && (
                            <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Token</TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredPayments.length === 0 ? (
                          <TableRow>
                            <TableCell colSpan={selectedBlockchain === 'ethereum' ? 7 : 6} sx={{ color: 'white', textAlign: 'center' }}>
                              No payments found
                            </TableCell>
                          </TableRow>
                        ) : (
                          filteredPayments
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((payment, index) => (
                              <TableRow
                                key={index}
                                hover
                                onClick={() => handlePaymentSelect(payment)}
                                sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#2c7fb8' } }}
                              >
                                <TableCell sx={{ color: 'white' }}>{payment.id}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{new Date(payment.queuedTime * 1000).toLocaleString("en-GB")}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{payment.paymentType}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{payment.status}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{payment.amount}</TableCell>
                                <TableCell sx={{ color: 'white' }}>{payment.to}</TableCell>
                                {payment.blockchain === 'ethereum' && (
                                  <TableCell sx={{ color: 'white' }}>{payment.token}</TableCell>
                                )}
                              </TableRow>
                            ))
                        )}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 30, 50]}
                  component="div"
                  count={filteredPayments.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ color: 'white', backgroundColor: 'gray' }}
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {/* Section 3: Payment Details */}
        {selectedPayment && (
          <Grid item xs={12}>
            <Accordion 
              sx={{ 
                backgroundColor: 'gray', 
                color: 'white',
                '& .MuiAccordionSummary-root': {
                  backgroundColor: 'gray',
                  color: 'white'
                }
              }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="payment-details-content"
                id="payment-details-header"
              >
                <Typography variant="h6">Payment Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}> {/* Blockchain */}
                    <TextField
                      fullWidth
                      label="Blockchain"
                      value={selectedPayment.blockchain}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* ID */}
                    <TextField
                      fullWidth
                      label="ID"
                      value={selectedPayment.id}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Type */}
                    <TextField
                      fullWidth
                      label="Payment Type"
                      value={selectedPayment.paymentType}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Batching Type */}
                    <TextField
                      fullWidth
                      label="Batching Type"
                      value={selectedPayment.batchType}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Requestor ID */}
                    <TextField
                      fullWidth
                      label="Requestor ID"
                      value={selectedPayment.requestorId}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  {selectedPayment.paymentType !== 'RBF' && (
                  <Grid item xs={12} md={6}> {/* Priority */}
                    <TextField
                      fullWidth
                      label="Priority"
                      value={selectedPayment.priority}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  )}
                  <Grid item xs={12} md={6}> {/* Status */}
                    <TextField
                      fullWidth
                      label="Status"
                      value={selectedPayment.status}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Fee Limit */}
                    <TextField
                      fullWidth
                      label="Fee Limit"
                      value={(() => {
                        const fee = parseInt(selectedPayment.feeLimit ?? selectedPayment.newMaxFeeLimit);
                        return fee === 0 ? 'unlimited (0)' : fee;
                      })()}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}> {/* Account */}
                    <TextField
                      fullWidth
                      label="Account"
                      value={(() => {
                        if (selectedPayment.account && blockchainsAndAccounts[selectedPayment.blockchain]) {
                          const accountInfo = blockchainsAndAccounts[selectedPayment.blockchain].find(
                            acc => parseInt(acc.id) === parseInt(selectedPayment.account)
                          );
                          return accountInfo ? `${accountInfo.data.name} (${accountInfo.id})` : selectedPayment.account;
                        }
                        return selectedPayment.account || '';
                      })()}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  {selectedPayment.paymentType !== 'RBF' && selectedPayment.batchType !== 'batched' && (
                  <Grid item xs={12} md={6}> {/* Source */}
                    <TextField
                      fullWidth
                      label="Source"
                      value={selectedPayment.source}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  )}
                  {selectedPayment.source === 'wallet' && (
                    <Grid item xs={12} md={6}> {/* From Wallet */}
                      <TextField
                        fullWidth
                        label="From Wallet"
                        value={selectedPayment.fromWallet}
                        disabled
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          },
                          sx: { color: 'white' }
                        }}
                        sx={{
                          ...textFieldStyle,
                          '& .MuiInputBase-input.Mui-disabled': {
                            color: 'white',
                            WebkitTextFillColor: 'white'
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {selectedPayment.paymentType !== 'RBF' && (
                  <Grid item xs={12} md={6}> {/* Amount */}
                    <TextField
                      fullWidth
                      label={
                        selectedPayment.blockchain === 'bitcoin'
                          ? 'Amount (Satoshi)'
                          : selectedPayment.blockchain === 'ethereum'
                            ? selectedPayment.token !== 'ETH'
                              ? `Amount (${selectedPayment.token})`
                              : 'Amount (Wei)'
                            : 'Amount'
                      }
                      value={selectedPayment.amount}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  )}
                  {selectedPayment.blockchain === 'ethereum' && (
                    <Grid item xs={12} md={6}> {/* Token */}
                      <TextField
                        fullWidth
                        label="Token"
                        value={selectedPayment.token}
                        disabled
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          },
                          sx: { color: 'white' }
                        }}
                        sx={{
                          ...textFieldStyle,
                          '& .MuiInputBase-input.Mui-disabled': {
                            color: 'white',
                            WebkitTextFillColor: 'white'
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {selectedPayment.paymentType !== 'RBF' && (
                  <Grid item xs={12} md={6}> {/* To Wallet */}
                    <TextField
                      fullWidth
                      label="To Wallet"
                      value={selectedPayment.to}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        },
                        sx: { color: 'white' }
                      }}
                      sx={{
                        ...textFieldStyle,
                        '& .MuiInputBase-input.Mui-disabled': {
                          color: 'white',
                          WebkitTextFillColor: 'white'
                        }
                      }}
                    />
                  </Grid>
                  )}
                  {selectedPayment.paymentType === 'RBF' && (
                    <>
                      <Grid item xs={12} md={6}> {/* Replaced Payment ID */}
                        <TextField
                          fullWidth
                          label="Replaced Payment ID"
                          value={selectedPayment.requestIdToReplace}
                          disabled
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            },
                            sx: { color: 'white' }
                          }}
                          sx={{
                            ...textFieldStyle,
                            '& .MuiInputBase-input.Mui-disabled': {
                              color: 'white',
                              WebkitTextFillColor: 'white'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}> {/* Replaced Payment Type */}
                        <TextField
                          fullWidth
                          label="Replaced Payment Type"
                          value={selectedPayment.replacedTransactionType}
                          disabled
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            },
                            sx: { color: 'white' }
                          }}
                          sx={{
                            ...textFieldStyle,
                            '& .MuiInputBase-input.Mui-disabled': {
                              color: 'white',
                              WebkitTextFillColor: 'white'
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {selectedPayment.broadcasted && (
                    <>
                      <Grid item xs={12} md={6}> {/* Transaction ID */}
                        <TextField
                          fullWidth
                          label="Transaction ID"
                          value={selectedPayment.transactionId}
                          disabled
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            },
                            sx: { color: 'white' }
                          }}
                          sx={{
                            ...textFieldStyle,
                            '& .MuiInputBase-input.Mui-disabled': {
                              color: 'white',
                              WebkitTextFillColor: 'white'
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}> {/* Broadcast Time */}
                        <TextField
                          fullWidth
                          label="Broadcast Time"
                          value={new Date(selectedPayment.broadcastTime * 1000).toLocaleString("en-GB")}
                          disabled
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            },
                            sx: { color: 'white' }
                          }}
                          sx={{
                            ...textFieldStyle,
                            '& .MuiInputBase-input.Mui-disabled': {
                              color: 'white',
                              WebkitTextFillColor: 'white'
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {selectedPayment.err && (
                    <>
                      <Grid item xs={12} md={6}> {/* Error Time */}
                        <TextField
                          fullWidth
                          label="Error Time"
                          value={new Date(selectedPayment.errorTime * 1000).toLocaleString("en-GB")}
                          disabled
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          },
                          sx: { color: 'white' }
                        }}
                        sx={{
                          ...textFieldStyle,
                          '& .MuiInputBase-input.Mui-disabled': {
                            color: 'white',
                            WebkitTextFillColor: 'white'
                          }
                        }}
                        />
                      </Grid>
                      <Grid item xs={12}> {/* Error Message */}
                        <TextField
                          fullWidth
                          label="Error Message"
                          value={selectedPayment.err}
                          disabled
                          multiline
                          rows={4}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            },
                            sx: { color: 'white' }
                          }}
                          sx={{
                            ...textFieldStyle,
                            '& .MuiInputBase-input.Mui-disabled': {
                              color: 'white',
                              WebkitTextFillColor: 'white'
                            }
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {selectedPayment.from?.metaDetails?.feeWalletAddress && (
                    <Grid item xs={12} md={6}> {/* Fee Wallet */}
                      <TextField
                        fullWidth
                        label="Fee Wallet"
                        value={selectedPayment.from.metaDetails.feeWalletAddress}
                        disabled
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          },
                          sx: { color: 'white' }
                        }}
                        sx={{
                          ...textFieldStyle,
                          '& .MuiInputBase-input.Mui-disabled': {
                            color: 'white',
                            WebkitTextFillColor: 'white'
                          }
                        }}
                      />
                    </Grid>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {/* Section 4: Create New Payment */}
        <Grid item xs={12}>
          <Accordion 
            sx={{ 
              backgroundColor: 'gray', 
              color: 'white',
              '& .MuiAccordionSummary-root': {
                backgroundColor: 'gray',
                color: 'white'
              }
            }}
            defaultExpanded={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              aria-controls="create-payment-content"
              id="create-payment-header"
            >
              <Typography variant="h6">Create New Payment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}> {/* Blockchain */}
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: 'white' }}>Blockchain</InputLabel>
                    <Select
                      value={createPaymentFormData.blockchain}
                      onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, blockchain: e.target.value }))}
                      label="Blockchain"
                      required
                      sx={{ color: 'white' }}
                    >
                      {BLOCKCHAINS.map((blockchain) => (
                        <MenuItem key={blockchain} value={blockchain}>
                          {blockchain}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}> {/* Account */}
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: 'white' }}>Account</InputLabel>
                    <Select
                      value={createPaymentFormData.account}
                      onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, account: e.target.value }))}
                      label="Account"
                      required
                      disabled={!createPaymentFormData.blockchain}
                      sx={{ color: 'white' }}
                    >
                      {createPaymentFormData.blockchain && blockchainsAndAccounts[createPaymentFormData.blockchain].map((account) => (
                        <MenuItem key={account.id} value={account.id}>
                          {account.data.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}> {/* ID */}
                  <TextField
                    fullWidth
                    label="ID"
                    value={createPaymentFormData.id}
                    onChange={(e) => {
                      const newValue = e.target.value.replace(/[\/\.\#\$\[\]]/g, '');
                      setCreatePaymentFormData(prev => ({ ...prev, id: newValue }));
                    }}
                    required
                    InputProps={{
                      style: { 
                        color: 'white',
                        caretColor: 'white'
                      }
                    }}
                    sx={textFieldStyle}
                    helperText="Characters '/', '.', '#', '$', '[', ']' are not allowed"
                  />
                </Grid>
                <Grid item xs={12} md={6}> {/* Type */}
                  <FormControl fullWidth>
                    <InputLabel sx={{ color: 'white' }}>Type</InputLabel>
                    <Select
                      value={createPaymentFormData.paymentType}
                      onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, paymentType: e.target.value }))}
                      label="Type"
                      sx={{ color: 'white' }}
                    >
                      {PAYMENT_TYPES.map((type) => (
                        <MenuItem key={type} value={type}>
                          {type}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {createPaymentFormData.paymentType !== 'RBF' && (
                  <>
                    <Grid item xs={12} md={6}> {/* Amount */}
                      <TextField
                        fullWidth
                        label="Amount"
                        type="number"
                        value={createPaymentFormData.amount}
                        onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, amount: e.target.value }))}
                        required
                        inputProps={{ min: 1 }}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    {createPaymentFormData.blockchain === 'ethereum' && (
                      <Grid item xs={12} md={6}> {/* Token */}
                        <FormControl fullWidth>
                          <InputLabel sx={{ color: 'white' }}>Token</InputLabel>
                          <Select
                            value={createPaymentFormData.token}
                            onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, token: e.target.value }))}
                            label="Token"
                            required
                            sx={{ color: 'white' }}
                          >
                            {createPaymentFormData.account && blockchainsAndAccounts[createPaymentFormData.blockchain]
                              .find(acc => acc.id === createPaymentFormData.account)
                              ?.data.settings.supportedTokens.map((token) => (
                                <MenuItem key={token} value={token}>
                                  {token}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}> {/* To Wallet*/}
                      <TextField
                        fullWidth
                        label="To Wallet Address"
                        value={createPaymentFormData.toWalletAddress}
                        onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, toWalletAddress: e.target.value }))}
                        required
                        inputProps={{ minLength: 40, maxLength: 50 }}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    {(createPaymentFormData.blockchain === 'ethereum' || (
                      createPaymentFormData.blockchain && (blockchainsAndAccounts[createPaymentFormData.blockchain]
                        .find(acc => acc.id === createPaymentFormData.account)
                        ?.data.settings.batchingDetails?.enabled) === false)) && (
                      <>
                        <Grid item xs={12} md={6}> {/* Priority */}
                          <FormControl fullWidth>
                            <InputLabel sx={{ color: 'white' }}>Priority</InputLabel>
                            <Select
                              value={createPaymentFormData.priority}
                              onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, priority: e.target.value }))}
                              label="Priority"
                              required
                              sx={{ color: 'white' }}
                            >
                              {PRIORITY_LEVELS.map((priority) => (
                                <MenuItem key={priority} value={priority}>
                                  {priority}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}> {/* Fee Limit */}
                          <TextField
                            fullWidth
                            label="Fee Limit (per byte / gas)"
                            type="number"
                            value={createPaymentFormData.feeLimit}
                            onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, feeLimit: e.target.value }))}
                            required
                            inputProps={{ min: 0 }}
                            InputProps={{
                              style: { 
                                color: 'white',
                                caretColor: 'white'
                              }
                            }}
                            sx={textFieldStyle}
                          />
                        </Grid>
                      </>
                    )}
                  </>
                )}
                {createPaymentFormData.paymentType === 'RBF' && (
                  <>
                    <Grid item xs={12} md={6}> {/* Replaced Payment ID */}
                      <TextField
                        fullWidth
                        label="Replaced Payment ID"
                        value={createPaymentFormData.replacedPaymentId}
                        onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, replacedPaymentId: e.target.value }))}
                        required
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    {createPaymentFormData.blockchain === 'bitcoin' && (
                      <Grid item xs={12} md={6}> {/* Replaced Payment Type */}
                        <FormControl fullWidth>
                          <InputLabel sx={{ color: 'white' }}>Replaced Payment Type</InputLabel>
                          <Select
                            value={createPaymentFormData.replacedPaymentType}
                            onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, replacedPaymentType: e.target.value }))}
                            label="Replaced Payment Type"
                            required
                            sx={{ color: 'white' }}
                          >
                            {REPLACED_PAYMENT_TYPES.map((type) => (
                              <MenuItem key={type} value={type}>
                                {type}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} md={6}> {/* Fee Limit */}
                      <TextField
                        fullWidth
                        label="Fee Limit (per byte / gas)"
                        type="number"
                        value={createPaymentFormData.feeLimit}
                        onChange={(e) => setCreatePaymentFormData(prev => ({ ...prev, feeLimit: e.target.value }))}
                        required
                        inputProps={{ min: 1 }}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={12}> {/* Send Payment */}
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="outlined"
                      onClick={handleCreate}
                      sx={{ color: 'white', borderColor: 'white' }}
                      disabled={
                        !createPaymentFormData.blockchain ||
                        !createPaymentFormData.account ||
                        !createPaymentFormData.id ||
                        (createPaymentFormData.paymentType === 'standard' && (
                          !createPaymentFormData.amount ||
                          !createPaymentFormData.toWalletAddress ||
                          ((createPaymentFormData.blockchain && (blockchainsAndAccounts[createPaymentFormData.blockchain]
                            .find(acc => acc.id === createPaymentFormData.account)
                            ?.data.settings.batchingDetails?.enabled) === false) && (
                              !createPaymentFormData.priority || 
                              !createPaymentFormData.feeLimit))
                        )) ||
                        (createPaymentFormData.paymentType === 'RBF' && (
                          !createPaymentFormData.replacedPaymentId ||
                          !createPaymentFormData.feeLimit ||
                          (createPaymentFormData.blockchain === 'bitcoin' && !createPaymentFormData.replacedPaymentType)
                        ))
                      }
                    >
                      Send Payment
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Snackbar 
        open={showSuccess} 
        autoHideDuration={6000} 
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#4caf50' }}>
          Operation completed successfully
        </Alert>
      </Snackbar>

      <Snackbar 
        open={showError} 
        autoHideDuration={6000} 
        onClose={() => setShowError(false)}
      >
        <Alert severity="error" sx={{ width: '100%', color: 'white', backgroundColor: '#f44336' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Payments; 