import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Box, FormControl, InputLabel, Select, MenuItem, TextField, Button, CircularProgress } from '@mui/material';
import Backend from './external/backend';

function Wallets() {
  const [blockchainsAndAccounts, setBlockchainsAndAccounts] = useState({});
  const [selectedBlockchain, setSelectedBlockchain] = useState('');
  const [selectedAccount, setSelectedAccount] = useState('');
  const [walletName, setWalletName] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState('');
  const [customers, setCustomers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [createdWalletAddress, setCreatedWalletAddress] = useState('');
  const [isCreating, setIsCreating] = useState(false);
  const [requireCustomer, setRequireCustomer] = useState(false);

  useEffect(() => {
    fetchBlockchainsAndAccounts();
    fetchCustomers();
  }, []);

  const fetchBlockchainsAndAccounts = async () => {
    setIsLoading(true);
    try {
      const chainsAndAccounts = {};
      const balanceData = await Backend.getBalance();
      
      for (const customerKey of Object.keys(balanceData)) {
        const customer = balanceData[customerKey];
        for (const blockchain of Object.keys(customer)) {
          chainsAndAccounts[blockchain] = [];
          for (const hdIndex of Object.keys(customer[blockchain])) {
            const accountInfo = {
              id: hdIndex,
              data: await Backend.getAccountInfo(blockchain, hdIndex)
            };
            chainsAndAccounts[blockchain].push(accountInfo);
          }
        }
      }
      setBlockchainsAndAccounts(chainsAndAccounts);
    } catch (error) {
      console.error("Error fetching blockchains and accounts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCustomers = async () => {
    try {
      const customersData = await Backend.getTransactionCustomers(undefined /* blockchain */, undefined /* hdIndex */, true /* sanitised */);
      setCustomers(customersData);
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const handleCreateWallet = async () => {
    setIsCreating(true);
    try {
      const result = await Backend.createBlockchainWallet(
        selectedBlockchain,
        selectedAccount,
        walletName,
        selectedCustomer
      );
      setCreatedWalletAddress(result.address);
    } catch (error) {
      console.error("Error creating wallet:", error);
    } finally {
      setIsCreating(false);
    }
  };

  const handleAccountSelect = (accountId) => {
    setSelectedAccount(accountId);
    setCreatedWalletAddress('');
    
    // Find the selected account's data to check if customer is required
    const account = blockchainsAndAccounts[selectedBlockchain].find(acc => acc.id === accountId);
    if (account) {
      setRequireCustomer(account.data.settings.requireTransactionCustomers);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
      <Paper sx={{ p: 3, backgroundColor: 'gray', color: 'white' }}>
        <Typography variant="h5" gutterBottom>
          Create New Wallet
        </Typography>
        
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
          <FormControl fullWidth>
            <InputLabel sx={{ color: 'white' }}>Blockchain</InputLabel>
            <Select
              value={selectedBlockchain}
              onChange={(e) => {
                setSelectedBlockchain(e.target.value);
                setSelectedAccount('');
                setCreatedWalletAddress('');
                setRequireCustomer(false);
              }}
              label="Blockchain"
              sx={{ color: 'white' }}
            >
              {Object.keys(blockchainsAndAccounts).map((blockchain) => (
                <MenuItem key={blockchain} value={blockchain}>
                  {blockchain}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth disabled={!selectedBlockchain}>
            <InputLabel sx={{ color: 'white' }}>Account</InputLabel>
            <Select
              value={selectedAccount}
              onChange={(e) => handleAccountSelect(e.target.value)}
              label="Account"
              sx={{ color: 'white' }}
            >
              {selectedBlockchain && blockchainsAndAccounts[selectedBlockchain].map((account) => (
                <MenuItem key={account.id} value={account.id}>
                  {account.data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            label="Wallet Name (Optional)"
            value={walletName}
            onChange={(e) => {
              setWalletName(e.target.value);
              setCreatedWalletAddress('');
            }}
            sx={{
              '& .MuiOutlinedInput-root': {
                color: 'white',
              },
              '& .MuiInputLabel-root': {
                color: 'white',
              },
            }}
          />

          <FormControl fullWidth>
            <InputLabel sx={{ color: 'white' }}>Customer {requireCustomer ? '(Required)' : '(Optional)'}</InputLabel>
            <Select
              value={selectedCustomer}
              onChange={(e) => {
                setSelectedCustomer(e.target.value);
                setCreatedWalletAddress('');
              }}
              label="Customer (Optional)"
              sx={{ color: 'white' }}
              displayEmpty
              renderValue={(selected) => {
                if (!selected) {
                  return '';
                }
                const customer = customers.find(c => c.id === selected);
                return customer ? customer.name : '';
              }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {customers.map((customer) => (
                <MenuItem key={customer.id} value={customer.id}>
                  {customer.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <Typography variant="caption" sx={{ mt: 1, color: 'rgba(255, 255, 255, 0.7)' }}>
            Minimum one of either wallet name or customer is required
          </Typography>

          <Button
            variant="contained"
            onClick={handleCreateWallet}
            disabled={!selectedBlockchain || !selectedAccount || (!walletName && !selectedCustomer) || (requireCustomer && !selectedCustomer) || isCreating}
            sx={{ mt: 2 }}
          >
            {isCreating ? <CircularProgress size={24} /> : 'Create Wallet'}
          </Button>

          {createdWalletAddress && (
            <TextField
              fullWidth
              label="Created Wallet Address"
              value={createdWalletAddress}
              InputProps={{
                readOnly: true,
              }}
              sx={{
                mt: 2,
                '& .MuiOutlinedInput-root': {
                  color: 'white',
                },
                '& .MuiInputLabel-root': {
                  color: 'white',
                },
              }}
            />
          )}
        </Box>
      </Paper>
    </Container>
  );
}

export default Wallets; 