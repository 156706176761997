import React, { useState, useEffect } from 'react';
import { Container, Typography, Paper, Grid, List, ListItem, ListItemText, CircularProgress, Box, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backend from './external/backend';
import AccountSettings from './components/AccountSettings';
import CreateAccount from './components/CreateAccount';

function Accounts() {
  const [blockchainsAndAccounts, setBlockchainsAndAccounts] = useState({});
  const [selectedBlockchain, setSelectedBlockchain] = useState(null);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchBlockchainsAndAccounts();
  }, []);

  const fetchBlockchainsAndAccounts = async () => {
    setIsLoading(true);
    try {
      const chainsAndAccounts = {};
      const balanceData = await Backend.getBalance();
      
      for (const customerKey of Object.keys(balanceData)) {
        const customer = balanceData[customerKey];
        for (const blockchain of Object.keys(customer)) {
          chainsAndAccounts[blockchain] = [];
          for (const hdIndex of Object.keys(customer[blockchain])) {
            const accountInfo = {
              id: hdIndex,
              data: await Backend.getAccountInfo(blockchain, hdIndex)
            };
            accountInfo.data['numOfWallets'] = Object.keys(customer[blockchain][hdIndex].breakdown ?? {}).length;
            chainsAndAccounts[blockchain].push(accountInfo);
          }
        }
      }
      setBlockchainsAndAccounts(chainsAndAccounts);
    } catch (error) {
      console.error("Error fetching blockchains and accounts:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleBlockchainSelect = (blockchain) => {
    setSelectedBlockchain(blockchain);
    setSelectedAccount(null);
  };

  const handleAccountSelect = (account) => {
    setSelectedAccount(account);
  };

  const handleAccountUpdate = (updatedAccountDetails) => {
    setBlockchainsAndAccounts(prev => {
      const updatedAccounts = { ...prev };
      updatedAccounts[selectedBlockchain] = updatedAccounts[selectedBlockchain].map(account => {
        if(account.id === selectedAccount.id) {
          account.data.name = updatedAccountDetails.name;
          account.data.settings = updatedAccountDetails.settings;
        }
        return account;
        }
      );
      return updatedAccounts;
    });
  };

  const handleCreateAccount = () => {
    fetchBlockchainsAndAccounts();
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Blockchains Column */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ 
            p: 2, 
            backgroundColor: 'gray', 
            color: 'white', 
            height: '300px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>
              Blockchains
            </Typography>
            <List sx={{ 
              flexGrow: 1,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                },
              },
            }}>
              {Object.keys(blockchainsAndAccounts).map((blockchain) => (
                <ListItem 
                  key={blockchain}
                  button
                  selected={selectedBlockchain === blockchain}
                  onClick={() => handleBlockchainSelect(blockchain)}
                  sx={{
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    }
                  }}
                >
                  <ListItemText 
                    primary={blockchain}
                    secondary={`${blockchainsAndAccounts[blockchain].length} accounts`}
                    secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Accounts Column */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ 
            p: 2, 
            backgroundColor: 'gray', 
            color: 'white', 
            height: '300px',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>
              Accounts {selectedBlockchain ? `for ${selectedBlockchain}` : ''}
            </Typography>
            <List sx={{ 
              flexGrow: 1,
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '8px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'rgba(255, 255, 255, 0.1)',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: 'rgba(255, 255, 255, 0.2)',
                borderRadius: '4px',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.3)',
                },
              },
            }}>
              {selectedBlockchain && blockchainsAndAccounts[selectedBlockchain].map((account) => (
                <ListItem 
                  key={account.id}
                  button
                  selected={selectedAccount && selectedAccount.id === account.id}
                  onClick={() => handleAccountSelect(account)}
                  sx={{
                    '&:hover': {
                      backgroundColor: 'rgba(255, 255, 255, 0.1)',
                    },
                    '&.Mui-selected': {
                      backgroundColor: 'rgba(255, 255, 255, 0.2)',
                    }
                  }}
                >
                  <ListItemText 
                    primary={account.data.name}
                    secondary={`${account.data.numOfWallets} wallets`}
                    secondaryTypographyProps={{ sx: { color: 'rgba(255, 255, 255, 0.7)' } }}
                  />
                </ListItem>
              ))}
              {!selectedBlockchain && (
                <ListItem sx={{ height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <ListItemText 
                    primary="Select a blockchain to view its accounts"
                    sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'center' }}
                  />
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>

      {/* Account Settings Section */}
      {selectedAccount && (
        <Accordion defaultExpanded={false} sx={{ backgroundColor: 'gray', color: 'white', mt: 2 }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="account-settings-content"
            id="account-settings-header"
          >
            <Typography variant="h6">Account Settings</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AccountSettings 
              blockchain={selectedBlockchain}
              accountId={selectedAccount.id}
              accountData={selectedAccount.data}
              onAccountUpdated={handleAccountUpdate}
            />
          </AccordionDetails>
        </Accordion>
      )}

      {/* Create Account Section */}
      <Accordion defaultExpanded={false} sx={{ backgroundColor: 'gray', color: 'white', mt: 2 }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="create-account-content"
          id="create-account-header"
        >
          <Typography variant="h6">Create New Account</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CreateAccount onCreateAccount={handleCreateAccount}/>
        </AccordionDetails>
      </Accordion>

    </Container>
  );
}

export default Accounts; 