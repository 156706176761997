import React, { useState, useEffect } from 'react';
import { 
  Container,
  Box, 
  TextField, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  Paper, 
  TablePagination,
  CircularProgress,
  Grid,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Alert,
  Snackbar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Backend from './external/backend';

// Common styles for text fields
const textFieldStyle = {
  input: { 
    color: 'white',
    '&::selection': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)'
    },
    cursor: 'text !important'
  },
  '& .MuiInputLabel-root': { color: 'white' },
  '& .MuiOutlinedInput-root': { 
    '& fieldset': { borderColor: 'white' },
    '&:hover fieldset': { borderColor: 'white' },
    '&.Mui-focused fieldset': { borderColor: 'white' },
    color: 'white',
    cursor: 'text !important'
  },
  '& .MuiFormHelperText-root': { color: 'white' },
  '& .MuiInputBase-root': {
    cursor: 'text !important'
  }
};

// Add these constants at the top of the file after the style definitions
const CUSTOMER_TYPES = ['individual', 'business', 'other'];
const CONTACT_METHOD_TYPES = ['email', 'mobile', 'phone', 'other'];

function Customers() {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [formData, setFormData] = useState({
    externalId: '',
    name: '',
    typeof: '',
    address: {
      city: '',
      country: '',
      houseNumber: '',
      streetName: '',
      zipcode: ''
    },
    contacts: [],
    id: undefined,
    creationTime: undefined
  });
  const [createCustomerFormData, setCreateCustomerFormData] = useState({
    externalId: '',
    name: '',
    typeof: '',
    address: {
      city: '',
      country: '',
      houseNumber: '',
      streetName: '',
      zipcode: ''
    },
    contacts: [],
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const data = await Backend.getTransactionCustomers(undefined, undefined, false);
      setCustomers(data);
      setFilteredCustomers(data);
    } catch (error) {
      console.error("Error fetching customers:", error);
      setErrorMessage('Failed to fetch customers');
      setShowError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (searchTerm === '') {
      setFilteredCustomers(customers);
    } else {
      const filtered = customers.filter(customer =>
        Object.entries(customer).some(([key, value]) => {
          if (key === 'creationTime') {
            return new Date(value * 1000).toLocaleString("en-GB").toLowerCase().includes(searchTerm.toLowerCase());
          }
          return value.toString().toLowerCase().includes(searchTerm.toLowerCase());
        })
      );
      setFilteredCustomers(filtered);
    }
    setPage(0);
  }, [searchTerm, customers]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleCustomerSelect = (customer) => {
    setSelectedCustomer(customer);
    setFormData({
      externalId: customer.externalId || '',
      name: customer.name || '',
      typeof: customer.typeof || '',
      address: customer.address || {
        city: '',
        country: '',
        houseNumber: '',
        streetName: '',
        zipcode: ''
      },
      contacts: customer.contacts || []
    });
    setIsEditing(false);
  };

  const handleChange = (path, isCreate) => {
    if(isCreate) {
      return function(event) {
        const value = event.target.value;
        setCreateCustomerFormData(prev => {
          const newData = { ...prev };
          const pathArray = path.split('.');
          let current = newData;
          
          for (let i = 0; i < pathArray.length - 1; i++) {
            current = current[pathArray[i]];
          }
          current[pathArray[pathArray.length - 1]] = value;
          
          return newData;
        });
      }
    }
    else {
      return function(event) {
        const value = event.target.value;
        setFormData(prev => {
          const newData = { ...prev };
          const pathArray = path.split('.');
          let current = newData;
          
          for (let i = 0; i < pathArray.length - 1; i++) {
            current = current[pathArray[i]];
          }
          current[pathArray[pathArray.length - 1]] = value;
          
          return newData;
        });
      }
    }
  }

  const handleContactChange = (index, field, value, isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => {
        const newData = { ...prev };
        newData.contacts[index] = {
          ...newData.contacts[index],
          [field]: value
        };
        return newData;
      });
    }
    else {
      setFormData(prev => {
        const newData = { ...prev };
        newData.contacts[index] = {
          ...newData.contacts[index],
          [field]: value
        };
        return newData;
      });
    }
  };

  const handleContactMethodChange = (contactIndex, methodIndex, field, value, isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods[methodIndex] = {
          ...newData.contacts[contactIndex].contactMethods[methodIndex],
          [field]: value
        };
        return newData;
      });
    }
    else {
      setFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods[methodIndex] = {
          ...newData.contacts[contactIndex].contactMethods[methodIndex],
          [field]: value
        };
        return newData;
      });
    }
  };

  const addContact = (isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => ({
        ...prev,
        contacts: [...prev.contacts, { name: '', contactMethods: [] }]
      }));
    }
    else {
      setFormData(prev => ({
        ...prev,
        contacts: [...prev.contacts, { name: '', contactMethods: [] }]
      }));
    }
  };

  const removeContact = (index, isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => ({
        ...prev,
        contacts: prev.contacts.filter((_, i) => i !== index)
      }));
    }
    else {
      setFormData(prev => ({
        ...prev,
        contacts: prev.contacts.filter((_, i) => i !== index)
      }));
    }
  };

  const addContactMethod = (contactIndex, isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods.push({ type: '', value: '' });
        return newData;
      });
    }
    else {
      setFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods.push({ type: '', value: '' });
        return newData;
      });
    }
  };

  const removeContactMethod = (contactIndex, methodIndex, isCreate) => {
    if(isCreate) {
      setCreateCustomerFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods = 
          newData.contacts[contactIndex].contactMethods.filter((_, i) => i !== methodIndex);
        return newData;
      });
    }
    else {
      setFormData(prev => {
        const newData = { ...prev };
        newData.contacts[contactIndex].contactMethods = 
          newData.contacts[contactIndex].contactMethods.filter((_, i) => i !== methodIndex);
        return newData;
      });
    }
  };

  const validateForm = (isCreate) => {
    const data = isCreate ? createCustomerFormData : formData;

    // Required fields and type validation
    if (!data.typeof || !CUSTOMER_TYPES.includes(data.typeof)) {
      setErrorMessage('Please select a valid customer type');
      return false;
    }
    if (!data.name) {
      setErrorMessage('Name is required');
      return false;
    }

    // Address validation
    if (data.address && Object.values(data.address).some(val => val)) {
      const requiredAddressFields = ['city', 'country', 'houseNumber', 'streetName'];
      const missingFields = requiredAddressFields.filter(field => !data.address[field]);
      if (missingFields.length > 0) {
        setErrorMessage('If address is provided, all address fields are required');
        return false;
      }
    }

    // Contact validation
    if (data.contacts.length > 0) {
      for (const contact of data.contacts) {
        if (!contact.name || contact.contactMethods.length === 0) {
          setErrorMessage('All contact fields are required');
          return false;
        }
        for (const method of contact.contactMethods) {
          if (!method.type || !CONTACT_METHOD_TYPES.includes(method.type)) {
            setErrorMessage('Please select a valid contact method type');
            return false;
          }
          if (!method.value) {
            setErrorMessage('Contact method value is required');
            return false;
          }
        }
      }
    }

    return true;
  };

  const handleSave = async () => {
    if (!validateForm(false)) {
      setShowError(true);
      return;
    }

    console.log('formData', JSON.stringify(formData, null, 2));

    try {
      const updatedFormData = { ...formData };
      updatedFormData.creationTime = selectedCustomer.creationTime;
      updatedFormData.id = selectedCustomer.id;
      if(updatedFormData.externalId === '') {
        delete updatedFormData.externalId;
      }
      if(updatedFormData.name === '') {
        delete updatedFormData.name;
      }
      if(updatedFormData.typeof === '') {
        delete updatedFormData.typeof;
      }
      if(updatedFormData.address?.city === '') {
        delete updatedFormData.address.city;
      }
      if(updatedFormData.address?.country === '') {
        delete updatedFormData.address.country;
      }
      if(updatedFormData.address?.houseNumber === '') {
        delete updatedFormData.address.houseNumber;
      }
      if(updatedFormData.address?.streetName === '') {
        delete updatedFormData.address.streetName;
      }
      if(updatedFormData.address?.zipcode === '') {
        delete updatedFormData.address.zipcode;
      }
      if(Object.values(updatedFormData.address).length === 0) {
        delete updatedFormData.address;
      }
      if(updatedFormData.contacts?.length === 0) {
        delete updatedFormData.contacts;
      }
      await Backend.updateTransactionCustomer(selectedCustomer.id, updatedFormData);
      setIsEditing(false);
      setShowSuccess(true);
      fetchCustomers();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to update customer');
      setShowError(true);
    }
  };

  const handleDeleteClick = () => {
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      await Backend.deleteTransactionCustomer(selectedCustomer.id);
      setSelectedCustomer(null);
      setShowSuccess(true);
      fetchCustomers();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to delete customer');
      setShowError(true);
    } finally {
      setDeleteDialogOpen(false);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
  };

  const handleCreate = async () => {
    if (!validateForm(true)) {
      setShowError(true);
      return;
    }

    try {
      if(createCustomerFormData.externalId === '') {
        delete createCustomerFormData.externalId;
      }
      if(createCustomerFormData.name === '') {
        delete createCustomerFormData.name;
      }
      if(createCustomerFormData.typeof === '') {
        delete createCustomerFormData.typeof;
      }
      if(createCustomerFormData.address.city === '') {
        delete createCustomerFormData.address.city;
      }
      if(createCustomerFormData.address.country === '') {
        delete createCustomerFormData.address.country;
      }
      if(createCustomerFormData.address.houseNumber === '') {
        delete createCustomerFormData.address.houseNumber;
      }
      if(createCustomerFormData.address.streetName === '') {
        delete createCustomerFormData.address.streetName;
      }
      if(createCustomerFormData.address.zipcode === '') {
        delete createCustomerFormData.address.zipcode;
      }
      if(Object.values(createCustomerFormData.address).length === 0) {
        delete createCustomerFormData.address;
      }
      if(createCustomerFormData.contacts.length === 0) {
        delete createCustomerFormData.contacts;
      }
      
      await Backend.createTransactionCustomer(createCustomerFormData);
      setCreateCustomerFormData({
        externalId: '',
        name: '',
        typeof: '',
        address: {
          city: '',
          country: '',
          houseNumber: '',
          streetName: '',
          zipcode: ''
        },
        contacts: [],
      });
      setShowSuccess(true);
      fetchCustomers();
    } catch (error) {
      setErrorMessage(error.message || 'Failed to create customer');
      setShowError(true);
    }
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        {/* Section 1: Customer List */}
        <Grid item xs={12}>
          <Paper sx={{ 
            p: 2, 
            backgroundColor: 'gray', 
            color: 'white',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <Typography variant="h6" gutterBottom>
              Customers
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search customers..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              InputProps={{
                style: { 
                  color: 'white',
                  caretColor: 'white'
                }
              }}
              sx={textFieldStyle}
            />
            <TableContainer component={Paper} sx={{ backgroundColor: 'gray' }}>
              <Table sx={{ color: 'white' }}>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>External ID</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Name</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Type</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>Creation Time</TableCell>
                    <TableCell sx={{ color: 'white', fontWeight: 'bold' }}>APS ID</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCustomers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((customer, index) => (
                      <TableRow
                        key={index}
                        hover
                        onClick={() => handleCustomerSelect(customer)}
                        sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#2c7fb8' } }}
                      >
                        <TableCell sx={{ color: 'white' }}>{customer.externalId}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{customer.name}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{customer.typeof}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{new Date(customer.creationTime * 1000).toLocaleString("en-GB")}</TableCell>
                        <TableCell sx={{ color: 'white' }}>{customer.id}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 30, 50]}
              component="div"
              count={filteredCustomers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{ color: 'white', backgroundColor: 'gray' }}
            />
          </Paper>
        </Grid>

        {/* Section 2: Customer Details */}
        {selectedCustomer && (
          <Grid item xs={12}>
            <Accordion 
              sx={{ 
                backgroundColor: 'gray', 
                color: 'white',
                '& .MuiAccordionSummary-root': {
                  backgroundColor: 'gray',
                  color: 'white'
                }
              }}
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
                aria-controls="customer-details-content"
                id="customer-details-header"
              >
                <Typography variant="h6">Customer Details</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="External ID"
                      value={formData.externalId}
                      onChange={handleChange('externalId', false)}
                      disabled={!isEditing}
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        }
                      }}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Name"
                      value={formData.name}
                      onChange={handleChange('name', false)}
                      disabled={!isEditing}
                      required
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        }
                      }}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      select
                      label="Type"
                      value={formData.typeof}
                      onChange={handleChange('typeof', false)}
                      disabled={!isEditing}
                      required
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        }
                      }}
                      sx={textFieldStyle}
                      SelectProps={{
                        native: true,
                        sx: { color: 'white',
                          '& option': {
                            color: 'black'
                          }
                        }
                      }}
                    >
                      <option value="" label=" "></option>
                      {CUSTOMER_TYPES.map((type) => (
                        <option key={type} value={type}>
                          {type.charAt(0).toUpperCase() + type.slice(1)}
                        </option>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="Creation Time"
                      value={new Date(selectedCustomer.creationTime * 1000).toLocaleString("en-GB")}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        }
                      }}
                      sx={textFieldStyle}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      label="ID"
                      value={selectedCustomer.id}
                      disabled
                      InputProps={{
                        style: { 
                          color: 'white',
                          caretColor: 'white'
                        }
                      }}
                      sx={textFieldStyle}
                    />
                  </Grid>

                  {/* Address Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Address</Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="City"
                          value={formData.address.city}
                          onChange={handleChange('address.city', false)}
                          disabled={!isEditing}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            }
                          }}
                          sx={textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Country"
                          value={formData.address.country}
                          onChange={handleChange('address.country', false)}
                          disabled={!isEditing}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            }
                          }}
                          sx={textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="House Number"
                          value={formData.address.houseNumber}
                          onChange={handleChange('address.houseNumber', false)}
                          disabled={!isEditing}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            }
                          }}
                          sx={textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Street Name"
                          value={formData.address.streetName}
                          onChange={handleChange('address.streetName', false)}
                          disabled={!isEditing}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            }
                          }}
                          sx={textFieldStyle}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          fullWidth
                          label="Zipcode"
                          value={formData.address.zipcode}
                          onChange={handleChange('address.zipcode', false)}
                          disabled={!isEditing}
                          InputProps={{
                            style: { 
                              color: 'white',
                              caretColor: 'white'
                            }
                          }}
                          sx={textFieldStyle}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  {/* Contacts Section */}
                  <Grid item xs={12}>
                    <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Contacts</Typography>
                    {formData.contacts.map((contact, contactIndex) => (
                      <Box key={contactIndex} sx={{ mb: 3, p: 2, border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextField
                              fullWidth
                              label="Contact Name"
                              value={contact.name}
                              onChange={(e) => handleContactChange(contactIndex, 'name', e.target.value, false)}
                              disabled={!isEditing}
                              required
                              InputProps={{
                                style: { 
                                  color: 'white',
                                  caretColor: 'white'
                                }
                              }}
                              sx={textFieldStyle}
                            />
                          </Grid>
                          {contact.contactMethods.map((method, methodIndex) => (
                            <Grid item xs={12} key={methodIndex}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={5}>
                                  <TextField
                                    fullWidth
                                    select
                                    label="Method Type"
                                    value={method.type}
                                    onChange={(e) => handleContactMethodChange(contactIndex, methodIndex, 'type', e.target.value, false)}
                                    disabled={!isEditing}
                                    required
                                    InputProps={{
                                      style: { 
                                        color: 'white',
                                        caretColor: 'white'
                                      }
                                    }}
                                    sx={textFieldStyle}
                                    SelectProps={{
                                      native: true,
                                      sx: { color: 'white',
                                        '& option': {
                                          color: 'black'
                                        }
                                      }
                                    }}
                                  >
                                    <option value="" label=" "></option>
                                    {CONTACT_METHOD_TYPES.map((type) => (
                                      <option key={type} value={type}>
                                        {type.charAt(0).toUpperCase() + type.slice(1)}
                                      </option>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                  <TextField
                                    fullWidth
                                    label="Method Value"
                                    value={method.value}
                                    onChange={(e) => handleContactMethodChange(contactIndex, methodIndex, 'value', e.target.value, false)}
                                    disabled={!isEditing}
                                    required
                                    InputProps={{
                                      style: { 
                                        color: 'white',
                                        caretColor: 'white'
                                      }
                                    }}
                                    sx={textFieldStyle}
                                  />
                                </Grid>
                                <Grid item xs={12} md={2}>
                                  <Button
                                    variant="outlined"
                                    color="error"
                                    onClick={() => removeContactMethod(contactIndex, methodIndex, false)}
                                    disabled={!isEditing}
                                    sx={{ height: '100%', color: 'white', borderColor: 'white' }}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ))}
                          <Grid item xs={12}>
                            <Button
                              variant="outlined"
                              onClick={() => addContactMethod(contactIndex, false)}
                              disabled={!isEditing}
                              sx={{ color: 'white', borderColor: 'white' }}
                            >
                              Add Contact Method
                            </Button>
                          </Grid>
                        </Grid>
                        {isEditing && (
                          <Button
                            variant="outlined"
                            color="error"
                            onClick={() => removeContact(contactIndex, false)}
                            sx={{ mt: 1, color: 'white', borderColor: 'white' }}
                          >
                            Remove Contact
                          </Button>
                        )}
                      </Box>
                    ))}
                    {isEditing && (
                      <Button
                        variant="outlined"
                        onClick={() => addContact(false)}
                        sx={{ color: 'white', borderColor: 'white' }}
                      >
                        Add Contact
                      </Button>
                    )}
                  </Grid>

                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'flex-end' }}>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={handleDeleteClick}
                        sx={{ color: 'white', borderColor: 'white' }}
                      >
                        Delete Customer
                      </Button>
                      <Button
                        variant="outlined"
                        onClick={() => isEditing ? handleSave() : setIsEditing(true)}
                        sx={{ color: 'white', borderColor: 'white' }}
                      >
                        {isEditing ? 'Save Changes' : 'Edit Customer'}
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}

        {/* Section 3: Create New Customer */}
        <Grid item xs={12}>
          <Accordion 
            sx={{ 
              backgroundColor: 'gray', 
              color: 'white',
              '& .MuiAccordionSummary-root': {
                backgroundColor: 'gray',
                color: 'white'
              }
            }}
            defaultExpanded={false}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'white' }} />}
              aria-controls="create-customer-content"
              id="create-customer-header"
            >
              <Typography variant="h6">Create New Customer</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="External ID"
                    value={createCustomerFormData.externalId}
                    onChange={handleChange('externalId', true)}
                    InputProps={{
                      style: { 
                        color: 'white',
                        caretColor: 'white'
                      }
                    }}
                    sx={textFieldStyle}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Name"
                    value={createCustomerFormData.name}
                    onChange={handleChange('name', true)}
                    required
                    InputProps={{
                      style: { 
                        color: 'white',
                        caretColor: 'white'
                      }
                    }}
                    sx={textFieldStyle}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    select
                    label="Type"
                    value={createCustomerFormData.typeof}
                    onChange={handleChange('typeof', true)}
                    required
                    InputProps={{
                      style: { 
                        color: 'white',
                        caretColor: 'white'
                      }
                    }}
                    sx={textFieldStyle}
                    SelectProps={{
                      native: true,
                      sx: { color: 'white',
                        '& option': {
                          color: 'black'
                        }
                      }
                    }}
                  >
                    <option value="" label=" "></option>
                    {CUSTOMER_TYPES.map((type) => (
                      <option key={type} value={type}>
                        {type.charAt(0).toUpperCase() + type.slice(1)}
                      </option>
                    ))}
                  </TextField>
                </Grid>

                {/* Address Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Address</Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="City"
                        value={createCustomerFormData.address.city}
                        onChange={handleChange('address.city', true)}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Country"
                        value={createCustomerFormData.address.country}
                        onChange={handleChange('address.country', true)}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="House Number"
                        value={createCustomerFormData.address.houseNumber}
                        onChange={handleChange('address.houseNumber', true)}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Street Name"
                        value={createCustomerFormData.address.streetName}
                        onChange={handleChange('address.streetName', true)}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        fullWidth
                        label="Zipcode"
                        value={createCustomerFormData.address.zipcode}
                        onChange={handleChange('address.zipcode', true)}
                        InputProps={{
                          style: { 
                            color: 'white',
                            caretColor: 'white'
                          }
                        }}
                        sx={textFieldStyle}
                      />
                    </Grid>
                  </Grid>
                </Grid>

                {/* Contacts Section */}
                <Grid item xs={12}>
                  <Typography variant="h6" sx={{ color: 'white', mb: 2 }}>Contacts</Typography>
                  {createCustomerFormData.contacts.map((contact, contactIndex) => (
                    <Box key={contactIndex} sx={{ mb: 3, p: 2, border: '1px solid rgba(255, 255, 255, 0.1)', borderRadius: 1 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            label="Contact Name"
                            value={contact.name}
                            onChange={(e) => handleContactChange(contactIndex, 'name', e.target.value, true)}
                            required
                            InputProps={{
                              style: { 
                                color: 'white',
                                caretColor: 'white'
                              }
                            }}
                            sx={textFieldStyle}
                          />
                        </Grid>
                        {contact.contactMethods.map((method, methodIndex) => (
                          <Grid item xs={12} key={methodIndex}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={5}>
                                <TextField
                                  fullWidth
                                  select
                                  label="Method Type"
                                  value={method.type}
                                  onChange={(e) => handleContactMethodChange(contactIndex, methodIndex, 'type', e.target.value, true)}
                                  required
                                  InputProps={{
                                    style: { 
                                      color: 'white',
                                      caretColor: 'white'
                                    }
                                  }}
                                  sx={textFieldStyle}
                                  SelectProps={{
                                    native: true,
                                    sx: { color: 'white',
                                      '& option': {
                                        color: 'black'
                                      }
                                    }
                                  }}
                                >
                                  <option value="" label=" "></option>
                                  {CONTACT_METHOD_TYPES.map((type) => (
                                    <option key={type} value={type}>
                                      {type.charAt(0).toUpperCase() + type.slice(1)}
                                    </option>
                                  ))}
                                </TextField>
                              </Grid>
                              <Grid item xs={12} md={5}>
                                <TextField
                                  fullWidth
                                  label="Method Value"
                                  value={method.value}
                                  onChange={(e) => handleContactMethodChange(contactIndex, methodIndex, 'value', e.target.value, true)}
                                  required
                                  InputProps={{
                                    style: { 
                                      color: 'white',
                                      caretColor: 'white'
                                    }
                                  }}
                                  sx={textFieldStyle}
                                />
                              </Grid>
                              <Grid item xs={12} md={2}>
                                <Button
                                  variant="outlined"
                                  color="error"
                                  onClick={() => removeContactMethod(contactIndex, methodIndex, true)}
                                  sx={{ height: '100%', color: 'white', borderColor: 'white' }}
                                >
                                  Remove
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        ))}
                        <Grid item xs={12}>
                          <Button
                            variant="outlined"
                            onClick={() => addContactMethod(contactIndex, true)}
                            sx={{ color: 'white', borderColor: 'white' }}
                          >
                            Add Contact Method
                          </Button>
                        </Grid>
                      </Grid>
                      <Button
                        variant="outlined"
                        color="error"
                        onClick={() => removeContact(contactIndex, true)}
                        sx={{ mt: 1, color: 'white', borderColor: 'white' }}
                      >
                        Remove Contact
                      </Button>
                    </Box>
                  ))}
                  <Button
                    variant="outlined"
                    onClick={() => addContact(true)}
                    sx={{ color: 'white', borderColor: 'white' }}
                  >
                    Add Contact
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="outlined"
                      onClick={handleCreate}
                      sx={{ color: 'white', borderColor: 'white' }}
                    >
                      Create Customer
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>

      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        PaperProps={{
          sx: {
            backgroundColor: 'gray',
            color: 'white'
          }
        }}
      >
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: 'white' }}>
            Are you sure you want to delete this customer? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDeleteCancel}
            sx={{ color: 'white' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            color="error"
            sx={{ color: 'white' }}
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar 
        open={showSuccess} 
        autoHideDuration={6000} 
        onClose={() => setShowSuccess(false)}
      >
        <Alert severity="success" sx={{ width: '100%', color: 'white', backgroundColor: '#4caf50' }}>
          Operation completed successfully
        </Alert>
      </Snackbar>

      <Snackbar 
        open={showError} 
        autoHideDuration={6000} 
        onClose={() => setShowError(false)}
      >
        <Alert severity="error" sx={{ width: '100%', color: 'white', backgroundColor: '#f44336' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default Customers; 